var tileLinks   = document.querySelectorAll('.js-tile--link'),
    tilesCarousel = document.querySelector('.js-tiles__carousel'),
    tilesSection  = document.querySelector('.js-tiles__section'),
    mousePosX = 0, xPos = 0, caruselInterval = null, xTouchPos = 0, xTouch = 0, touchCaruselInterval = null;

function init() {
    if(tilesCarousel){
        if (tileLinks.length > 3) {
            if (window.innerWidth > 767) {
                
                tilesSection.addEventListener('mousemove', function(ev){
                    mousePosX = ev.pageX;
                }, false);
    
                tilesCarousel.addEventListener('mouseenter', caruselAnimation, false);
                tilesCarousel.addEventListener('mouseleave', function(){
                    clearInterval(caruselInterval);
                }, false);
    
    
                tilesSection.addEventListener('touchmove', function(ev){
                    xTouchPos = ev.touches[0].pageX;
                }, false);
    
                tilesCarousel.addEventListener('touchstart', touchHandler, false);
                
            } else {
                tilesCarousel.style.left = '0';
                clearInterval(caruselInterval);
                tilesCarousel.removeEventListener('mouseenter', caruselAnimation, false);
            }
        } else {
            [].forEach.call(tileLinks, function(titleLink) {
                titleLink.classList.add('lte3')
            })
        }
    }
}

function caruselAnimation() {
    var ratio = ( tileLinks.length * tileLinks[0].offsetWidth ) / window.innerWidth;

    var center = window.innerWidth / 2;
    var speed = 0;

    caruselInterval = setInterval(function(){ 
        if (mousePosX > center) {
            speed = 250 - (mousePosX - center) / 3;
        } 
        else {
            speed = 250 - (center - mousePosX) / 3;
        }
        if (speed < 50) {
            speed = 50;
        }
        xPos += (( mousePosX * ratio ) - xPos) / speed;
        if (mousePosX < center ) {
            xPos += ( ( ( mousePosX * ratio ) - center ) - xPos ) / speed;
        }
        tilesCarousel.style.left = -xPos + 'px';
    }, 30);
}

function touchHandler(ev){
    tilesCarousel.removeEventListener('mouseenter', caruselAnimation, false);
    tilesSection.removeEventListener('mousemove', function(e){
        mousePosX = e.pageX;
    }, false);
    clearInterval(touchCaruselInterval);

    var firstTouchX = ev.touches[0].clientX;
    var ratio = ( tileLinks.length * tileLinks[0].offsetWidth ) / window.innerWidth;

    touchCaruselInterval = setInterval(function(){ 
        if (firstTouchX > xTouchPos) {
            if( xTouch > -( ( window.innerWidth * ratio ) - 400) ) {
                
                xTouch += -( firstTouchX - xTouchPos ) / 20;
            }
        } else if(firstTouchX < xTouchPos) {
            if( xTouch <= 200) {
                xTouch += ( ( xTouchPos - firstTouchX ) ) / 20;
            }
        }

        tilesCarousel.style.left = xTouch + 'px';
    }, 30);
}

window.addEventListener('resize', function(){
    init();
});

init();



