/*global Slideout*/
(function ($) {
    'use strict';

    var
        navMainToggle = document.querySelector('.js-toggle-nav-main'),
        navMainChildrenToggle = document.querySelectorAll('.js-toggle-nav-main-mobile-children, .nav-main-mobile__item--level-01 > .js-nav-main-mobile-link-wrapper'),
        navMainLink = document.querySelectorAll('.js-nav-main-link-wrapper'),
        heroHome = document.querySelector('.hero--home'),
        searchButton = document.querySelector('.js-header-search__toggle-button'),
        navList = document.querySelector('.js-nav-main__list'),
        searchForm = document.querySelector('#header-search__form'),
        searchInput = document.querySelector('#header-search__input'),
        navOverlay = document.querySelector('.js-nav-main__overlay'),
        mainContainer = document.getElementById('main'),
        currentWidth = window.innerWidth,
        slideOptions = {
            'panel':     document.getElementById('page-wrapper'),
            'menu':      document.getElementById('navigation'),
            'padding':   245,
            'side':      'right',
            'tolerance': 200,
            'touch':     false
        },
        slideout,
        navMainFlag = false;

    /*
    * Toggles the state of a toggle button.
    */
    function toggleButton(button) {
        if (button.getAttribute('aria-pressed') === 'true') {
            button.setAttribute('aria-pressed', 'false');
        }
        else {
            button.setAttribute('aria-pressed', 'true');
        }
    }

    /*
    * Toggles the state of the child list.
    */
    function toggleNavMainChildren(button) {

        var isPressed = button.getAttribute('aria-pressed') === 'true' ? true : false,
            childList = button.parentNode.querySelector('.js-nav-main-mobile-children'),
            parentItem = button.parentNode,
            actualButton = button.parentNode.querySelector('.js-toggle-nav-main-mobile-children'),
            linkWrapper = button.parentNode.querySelector('.js-nav-main-mobile-link-wrapper');
        //close expand menu
        [].forEach.call(navMainChildrenToggle, function(navMainChildren){
            if( navMainChildren !== button && !button.parentNode.classList.contains('nav-main-mobile__item--level-02')){
                if (navMainChildren.getAttribute('aria-pressed') === 'true') {
                    navMainChildren.setAttribute('aria-pressed', 'false');
                }
                if(navMainChildren.classList.contains('icon-arrow-down')){
                    navMainChildren.classList.remove('icon-arrow-down');
                    navMainChildren.classList.add('icon-arrow-right');
                    navMainChildren.parentNode.classList.remove('nav-main-mobile__item--expanded');
                    navMainChildren.parentNode.querySelector('.js-nav-main-mobile-children')
                        .classList.remove('nav-main-mobile__dropdown--expanded');
                    navMainChildren.parentNode.querySelector('.js-nav-main-mobile-children').style.height = '';
                }
            }
        });

        if (isPressed) {
            // Display collapsed arrow
            actualButton.classList.remove('icon-arrow-down');
            actualButton.classList.add('icon-arrow-right');
            // Hide child list
            childList.classList.remove('nav-main-mobile__dropdown--expanded');
            parentItem.classList.remove('nav-main-mobile__item--expanded');
            childList.style.height = '';

        } else {
            // Display expanded arrow
            actualButton.classList.remove('icon-arrow-right');
            actualButton.classList.add('icon-arrow-down');
            // Show child list
            childList.classList.add('nav-main-mobile__dropdown--expanded');
            parentItem.classList.add('nav-main-mobile__item--expanded');
            // childList.style.height = childList.querySelector('.nav-main-mobile__list').offsetHeight + 'px';
            childList.style.height = 'auto';
        }
        // Toggle button state
        toggleButton(linkWrapper);
        toggleButton(actualButton);
    }

    function searchToggleButton(el, listNav) {
        if(el.getAttribute('aria-pressed') === 'true') {
            el.style.display = 'none';
            listNav.style.setProperty('visibility', 'hidden');
            document.querySelector('.header').style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
            searchForm.style.display = 'block';
            // [].forEach.call(document.querySelectorAll('.nav-main__dropdown'), function(navChildren){
            //     navChildren.style.visibility = 'hidden';
            // });

        } else {
            listNav.style.visibility = '';
            document.querySelector('.header').style.backgroundColor = '';
            searchForm.style.display = '';
            // [].forEach.call(document.querySelectorAll('.nav-main__dropdown'), function(navChildren){
            //     navChildren.style.visibility = 'visible';
            // });
        }
    }

    function handlerMenu(el, action) {
        [].forEach.call(document.querySelectorAll('.js-nav-main-children'), function(mainChildren){
            mainChildren.style.height = '';
            mainChildren.style.opacity = '';
            mainChildren.style.visibility = '';
        });

        if (action === 'remove' && navMainFlag) {
            navOverlay.style.height = '';
            // if (heroHome) {
            //     heroHome.style.paddingTop = '';
            // } else {
            //     document.querySelector('.main-wrapper').style.paddingTop = '';
            // }

            [].forEach.call(navMainLink, function(link) {
                link.classList.remove('js-nav-main-link-wrapper--active');
            });

            navMainFlag = false;
        } else if (action === 'add' && !navMainFlag) {
            var currentHeight = el.querySelector('.nav-main__list--level-02').offsetHeight;

            [].forEach.call(navMainLink, function(link) {
                link.classList.remove('js-nav-main-link-wrapper--active');
            });
            el.classList.add('js-nav-main-link-wrapper--active');

            el.querySelector('.js-nav-main-children').style.height = currentHeight + 'px';
            el.querySelector('.js-nav-main-children').style.opacity = '1';
            el.querySelector('.js-nav-main-children').style.visibility = 'visible';

            navOverlay.style.setProperty('height', currentHeight + 'px');

            // if (heroHome){
            //     heroHome.style.paddingTop = currentHeight + 185 + 'px';
            // } else {
            //     document.querySelector('.main-wrapper').style.paddingTop = currentHeight + 'px';
            // }

            navMainFlag = true;
        }
    }

    function handlerSearch(el) {
        if(document.querySelector('.js-header-search__toggle-button')) {
            if(el.classList.contains('js-header-search__toggle-button')) {
                toggleButton(searchButton);
                searchToggleButton(searchButton, navList);
                searchInput.focus();
            } else if(el.getAttribute('id') !== 'megamenu' && !el.classList.contains('header__wrapper') && !el.classList.contains('header') && el.getAttribute('id') !== 'header-search__input') {
                searchButton.setAttribute('aria-pressed', 'false');
                searchToggleButton(searchButton, navList);
                searchButton.style.display = '';
            }
        }
    }

    function expandedMobileMenu() {
        [].forEach.call(document.querySelectorAll('.js-nav-main-mobile-children'), function(mobilChild){
            if(mobilChild.querySelector('.nav-main-mobile__item--current')){
                mobilChild.classList.add('nav-main-mobile__dropdown--expanded');
                mobilChild.parentNode.classList.add('nav-main-mobile__item--expanded');
                toggleNavMainChildren(mobilChild.parentNode.querySelector('.js-toggle-nav-main-mobile-children'));

            }
        });
    }

    navMainToggle.addEventListener('click', function() {
        toggleButton(this);

        if(this.getAttribute('aria-pressed') === 'true') {
            this.children[0].classList.remove('icon-header-menu');
            this.children[0].classList.add('icon-close');
        } else {
            this.children[0].classList.remove('icon-close');
            this.children[0].classList.add('icon-header-menu');
        }

        slideout.toggle();
        expandedMobileMenu();
    });

    [].forEach.call(navMainChildrenToggle, function(button) {
        button.addEventListener('click', function(ev) {
            toggleNavMainChildren(ev.currentTarget);
        });
    });

    [].forEach.call(navMainLink, function(menuLink) {
        menuLink.children[0].addEventListener('click', function(ev) {
            var currentActive = document.getElementsByClassName('js-nav-main-link-wrapper--active');

            if( ev.target.parentNode.classList.contains('js-nav-main-link-wrapper--active') ) {
                handlerMenu(ev.currentTarget.parentNode, 'remove');
            } else {
                handlerMenu(currentActive, 'remove');
                handlerMenu(ev.currentTarget.parentNode, 'add');
            }

            ev.preventDefault();
        });
    });

    slideout = new Slideout(slideOptions);

    window.addEventListener('resize', function() {
        if ( window.innerWidth !== currentWidth ) {
            currentWidth = window.innerWidth;
            slideout.destroy();
            slideout = new Slideout(slideOptions);

            if(navMainToggle.children[0].classList.contains('icon-close')) {
                navMainToggle.setAttribute('aria-pressed', 'false');
                navMainToggle.children[0].classList.remove('icon-close');
                navMainToggle.children[0].classList.add('icon-header-menu');
            }

            if (searchButton) {
                searchButton.setAttribute('aria-pressed', 'false');
                searchToggleButton(searchButton, navList);
                searchButton.style.display = '';
            }
        }
    });

    document.addEventListener('click', function(ev) {
        handlerSearch(ev.target);
    });

    mainContainer.addEventListener('click', function(ev) {
        handlerMenu(ev.currentTarget, 'remove');
    });

    mainContainer.addEventListener('touchstart', function() {
        if(slideout.isOpen()) {
            slideout.close();
            toggleButton(navMainToggle);
            if(navMainToggle.getAttribute('aria-pressed') === 'true') {
                navMainToggle.children[0].classList.remove('icon-header-menu');
                navMainToggle.children[0].classList.add('icon-close');
            } else {
                navMainToggle.children[0].classList.remove('icon-close');
                navMainToggle.children[0].classList.add('icon-header-menu');
            }
        }  
    });

    document.addEventListener('touchstart', function(ev){
        if(ev.target.getAttribute('id') !== '§megamenu' && !ev.target.classList.contains('header__wrapper') && !ev.target.classList.contains('header') && ev.target.getAttribute('id') !== 'header-search__input') {
            searchButton.setAttribute('aria-pressed', 'false');
            searchToggleButton(searchButton, navList);
            searchButton.style.display = '';
        }

        if (!ev.target.classList.contains('nav-main__link--level-01')){
            handlerMenu(ev.currentTarget, 'remove');
        }
    });

    document.querySelector('#nav-main-mobile__search-input').addEventListener('click', function(){
        $(this).focus();
    }, false);

    // If main nav dropdown list is equal to or less then 4, change width to auto for li second level
    $("ul.nav-main__list--level-02").filter(function () {
        return $(this).find('li.nav-main__item--level-02').length <= 4
    }).addClass("shortNav");
}(jQuery));
