(function($){

    var $tabs      =  $('.tabs'),
        $tabsList  =  $('.tabs__tab-list'),
        $slick,
        slideCount;

    /*
     *  Tabs to Accordion
     */
    var tab_count = 0;
    $('.tabs').each(function(){
        var wrapper = $('<div class="accordion accordion--mobile"></div>');

        // Increment count to create unique id's
        tab_count+=1;

        // Create accordion content
        $(this).find('.tabs__tab-list .tabs__link').each(function(){
            var title = $(this).text(),
                id   = $(this).attr('href')+tab_count,
                content = $($(this).attr('href')).html();

            wrapper.append('<div class="accordion__item"><h2><a href="'+id+'" class="accordion__link">'+title+'</a></h2><div class="accordion__target" id="'+id.replace('#','')+'"><div class="accordion__target--inner">'+content+'</div></div></div>')
        });

        // Place accordion after tab
        $(this).after(wrapper);
    });

    /*
     *  Accordion
     */
    $('.accordion').UberAccordion({
        buttonClass:          'accordion__link',
        toggle:               true,
        multiple:             true,
        expandOnFocus:        false,
        preventDefaultButton: true,
        hashChange:           true
    });

    // Second example is a tab-like setup
    $('.tabs').UberAccordion({
        buttonClass: 'tabs__link',

        autoExpand: function() {
            return window.location.hash || '#tabs__target-1';
        },

        toggle:               false,
        multiple:             false,
        preventDefaultButton: true
    });


    /*
     *  Tabs
     */

    // Check is last tab list element visible
    var checkNextArrow = function(slick) {
        var showNextArrow,
            slickList = $(slick.$list),
            slickListWidth = slickList.width(),
            activeSlide = slickList.find('.slick-active'),
            tempWidth = activeSlide.outerWidth(true,true);

            activeSlide.nextAll().each(function(){
                tempWidth += $(this).outerWidth(true, true);
            });

        if (tempWidth > slickListWidth) {
            showNextArrow = true;
        } else {
            showNextArrow = false;
        }

        return showNextArrow;
    }

    if ($tabs.length) {

        $tabsList
            .on('afterChange', function (event, slick, currentSlide) {

                // Hide $prevArrow when no LHS slides to show
                if (currentSlide === 0) {
                    slick.$prevArrow.hide();
                } else {
                    slick.$prevArrow.show();
                }

                // Hide $nextArrow when last slide is visible
                if (!checkNextArrow(slick)) {
                    slick.$nextArrow.hide();
                } else {
                    slick.$nextArrow.show();
                }
            });

        if ($tabsList.length) {

            $tabsList.slick({
                accessibility:  false,
                slidesToScroll: 1,
                dots:           false,
                focusOnSelect:  false,
                centerMode:     false,
                infinite:       false,
                touchMove:      true,
                variableWidth:  true
            });

            $tabsList.each(function () {
                $slick     = $(this).slick('getSlick');
                slideCount = $slick.slideCount;

                // Hide prev arrow on init
                $slick.$prevArrow.hide();

                // Hide $nextArrow when last slide is visible
                if (!checkNextArrow($slick)) {
                    $slick.$nextArrow.hide();
                } else {
                    $slick.$nextArrow.show();
                }
            });
        }
    }

}(jQuery));
