(function($){
    'use strict';

    svg4everybody();

    // Stop user to search for empty inputs in the header
    // on desktop
    $('#header-search__form').submit(function() {
        if ($.trim($("#header-search__input").val()) === "") {
            return false;
        }
    });
    // on mobile
    $('#nav-main-mobile__search').submit(function() {
        if ($.trim($("#nav-main-mobile__search-input").val()) === "") {
            return false;
        }
    });

}(jQuery));