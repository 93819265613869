(function($){
    'use strict';
    var $searchInput = $('.search__input');
    if ($searchInput.length) {

    //search icon & clear icon
        var cancelIcon = $('.search__form .icon-close');
        var searchIcon = $('.search__form .icon-search');
        var inputText = $searchInput.val().length;

        if (inputText !== 0) {
        // show cross icon
            cancelIcon.show();
            searchIcon.hide();
        } else {
        // show search icon
            searchIcon.show();
            cancelIcon.hide();
        }

    // show search icon when focus
        $searchInput.focus(function () {
            cancelIcon.hide();
            searchIcon.show();
            $searchInput.attr('value', '');
        });

    // show search icon when click on clear icon

        cancelIcon.parent().click(function () {
            // console.log('click');
            cancelIcon.hide();
            searchIcon.show();
            $searchInput.attr('value', '');
        });
    }
}(jQuery));