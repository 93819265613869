(function($){
    'use strict';
    // ignite selectric
    $(function() {
        $('select').selectric({
            onInit: function() {
                //Add a hidden label around the selectric input for accessibility.
                $(".selectric-input").wrap("<label class='visuallyhidden' aria-hidden='true'>Please select an option</label>");
            },
            disableOnMobile: false,
            nativeOnMobile:  false
        });
    });

    // add arrow for button
    var arrowSpanBtn = '<span class="icon-arrow-right" aria-hidden="true"></span>',
    ButtonSelector = '.content .btn';
    $(arrowSpanBtn).insertAfter(ButtonSelector);

}(jQuery));
