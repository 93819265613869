if($('iframe.video__hide')) {
    // Inject YouTube API script
    var tag = document.createElement('script');
    tag.src = "//www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

var players = [];

function onYouTubePlayerAPIReady() {
    $('iframe.video__hide').each(function(){
        players.push(new YT.Player($(this).attr('id'), {
            events: {
                'onReady': onPlayerReady
            }
        }));
    });
}

function onPlayerReady() {
    $('.video__play').each(function(index){
        $(this).on('click', function() {
            players[index].playVideo();
        });
    });
}

(function($){
    'use strict';

    function videoPlayerHandler(e) {
        e.preventDefault();
        
        $(this).prev().addClass('video__on');
        $(this).siblings('iframe.video__hide').removeClass('video__hide');
        $(this).remove();
    }

    // Video play desktop
    $('.video__play').on('click', videoPlayerHandler);



    // Tables
    $('.content table').wrap('<div class="table_wrapper" />');

  
    $('.sq-form-question-text').find('textarea').on('keyup', function(){
        $('.sq-form-text-charcount').text($(this).val().length);

        if ($(this).val().length < 351){
            if( $(this).parents('.sq-form-question-text').hasClass('sq-form-char-error')) {
                $(this).parents('.sq-form-question-text').removeClass('sq-form-char-error');
                $('.sq-form-text-charlimit--msg').remove();
                $('input[type="submit"]').prop('disabled', false);
            }
        } else {
            $(this).parents('.sq-form-question-text').addClass('sq-form-char-error');

            if($('.sq-form-text-charlimit--msg').length < 1) {
                $('<span class="sq-form-text-charlimit--msg">Too many characters</span>').appendTo($('.sq-form-text-charlimit'));
            }
            
            $('input[type="submit"]').prop('disabled', true);
        }
    });
}(jQuery));